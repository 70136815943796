<template>
  <div>
    <div
        v-if="current_ads && showAds"
        class="home-overlay flex-center"
        @click="closeAd()"
        ref="overlay"
    >
      <v-img
          v-if="!current_ads.image_url == false"
          :src="current_ads.image_url"
          :alt="current_ads.title"
          class="big-ad-full"
          contain
      ></v-img>
      <div v-else style="height: auto; width: 100%">
        <v-img v-if="current_ads.video_url.substr(-4) === 'webp'" :src="current_ads.video_url"></v-img>
        <div v-else>
          <video v-if="length_ads > 1" @ended="endVideo()" id="myVideo" autoplay height="100%" width="100%" :src="current_ads.video_url"></video>
          <video v-else autoplay height="100%" width="100%" :src="current_ads.video_url" loop></video>
        </div>
<!--        <video v-else autoplay height="100%" width="100%" :src="current_ads.video_url" loop></video>-->
      </div>
    </div>
    <div v-else class="legal flex-center flex-center flex-column">
    <v-container fluid class="legal-1 py-8" v-if="client">
    </v-container>

    <div class="header w-100 flex-center py-8">
      <v-container class="w-100">
        <v-btn color="#272c33" depressed height="50" dark class="main-btn pl-2 mr-4 mb-4"
               @click="legalBack">
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t('back') }}
        </v-btn>
      </v-container>
    </div>

    <v-container class="legal-2 py-5" v-if="client">
      <p class="mb-0 transparent-text text-center">{{ $t('companyName') }}</p>
      <p class="mb-0 white--text title text-center">{{ client.title }}</p>

      <br/>
      <p class="mb-0 transparent-text text-center">{{ $t('address') }}</p>
      <p class="mb-0 white--text title text-center">{{ client.address }}</p>

      <br/>
      <p class="mb-0 transparent-text text-center">ID</p>
      <p class="mb-0 white--text title text-center">{{ client.id_number }}</p>

      <br/>
      <p class="mb-0 transparent-text text-center">VAT</p>
      <p class="mb-0 white--text title text-center">{{ client.vat_number }}</p>

      <br/>
      <p class="mb-0 transparent-text text-center">{{ $t('complaints') }}</p>
      <template v-if="client.id == 8">
        <p v-if="client.id == 8" class="mb-0 mt-2 transparent-text text-center">
          {{ $t('sulphits') }}
        </p>
        <p class="mb-0 mt-2 transparent-text text-center">
          {{ $t('alergens') }}
        </p>
      </template>
      <br/>
      <v-btn color="#d7c194" class="text-none" @click="codeDialog = true">{{ $t('review_orders') }}</v-btn>
      <v-btn color="#d7c194" class="mt-2 text-none" @click="resetApp">{{ $t('Reset') }}</v-btn>
      <v-btn color="#d7c194" class="mt-2 text-none" @click="$router.push('all-products')">{{ $t('all_products') }}</v-btn>

    </v-container>
    <v-dialog v-model="orderDialog" class="order-dialog" max-width="700">
      <v-card class="pa-4">
        <div class="text-h6">{{ $t('review_orders') }}</div>
        <div style="height: 1px; background: linear-gradient(90deg,#fff,#d7c194,#fff);" class="mb-5 mt-2"></div>
        <v-row class="mb-5" >
          <v-col cols="6" v-for="(product, i) in products" :key="i">
            <v-card class="pa-2 card-outter" height="100%">

              <p style="font-size: 16px" class="font-weight-bold mb-0">{{ $t('order_num') }} {{ product.id }}</p>
              <p style="font-size: 16px" class="font-weight-bold mb-0">{{ $t('table_num') }} {{ product.table_number }}</p>
              <p style="font-size: 16px" class="mb-0"><b>{{ $t('note') }}</b> {{product.note}}</p>

              <v-divider class="mt-3"></v-divider>
              <v-card-text class="px-0 mb-10">
                <v-row class="py-0" align="center" v-for="(item, j) in product.products" :key="j">
                  <v-col cols="10" class="text-left pb-0">{{ item.title }}{{item.pivot.dose ? item.pivot.dose == 'bottle' ? ' (Boca)' : ' (Čaša)' : ''}}{{item.pivot.note ? ` - ${item.pivot.note}`: ''}}</v-col>
                  <v-col cols="2" class="text-right pb-0">{{ item.pivot.amount }}</v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pa-0 card-actions">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="code !== ''" v-bind="attrs" v-on="on" color="#d7c194" class="text-none" @click="changeStatus(product, code == '' ? true : false, 2, 'i=1,2')">
                      {{ $t('review') }}
                    </v-btn>
                    <v-btn v-else v-bind="attrs" v-on="on" color="#d7c194" class="text-none" @click="changeStatus(product, code == '' ? true : false, 1, 'i=1,3')">
                      {{ $t('review') }}
                    </v-btn>
                  </template>
                  <span>{{ $t('mark_order') }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
<!--          <v-col cols="8" class="text-left">-->
<!--            <div style="font-size: 16px" class="font-weight-bold">{{ $t('order_num') }} {{ product.id }}</div>-->
<!--            <div style="font-size: 16px" class="font-weight-bold">{{ $t('table_num') }} {{ product.table_number }}</div>-->
<!--            <div style="font-size: 16px"><b>{{ $t('note') }}</b> {{product.note}}</div>-->
<!--          </v-col>-->
<!--          <v-col cols="4" class="text-right">-->
<!--            <v-tooltip bottom>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn v-bind="attrs" v-on="on" color="#d7c194" class="text-none" @click="changeStatus(product)">-->
<!--                  {{ $t('review') }}-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <span>{{ $t('mark_order') }}</span>-->
<!--            </v-tooltip>-->
<!--          </v-col>-->
<!--          <v-col cols="12" class="py-0">-->
<!--            <v-row class="py-0" align="center" v-for="(item, j) in product.products" :key="j">-->
<!--              <v-col cols="10" class="text-left pb-0">{{ item.title }}{{item.pivot.dose ? item.pivot.dose == 'bottle' ? ' (Boca)' : ' (Čaša)' : ''}}</v-col>-->
<!--              <v-col cols="2" class="text-right pb-0">{{ item.pivot.amount }}</v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->
<!--          <v-col v-if="i < products.length-1">-->
<!--            <v-divider></v-divider>-->
<!--          </v-col>-->
        </v-row>
        <div style="height: 1px; background: linear-gradient(90deg,#fff,#d7c194,#fff);"></div>
        <v-row class="mt-0">
          <v-col class="text-right">
            <v-btn color="#d7c194" class="text-none" @click="orderDialog = false; code = ''; products = []">{{ $t('close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="codeDialog" class="order-dialog" max-width="400">
      <div class="pa-4">
        <v-card flat>
        <v-text-field
            v-model="code"
            :label="$t('password')"
            color="black"
            outlined
        ></v-text-field>
          <v-card-actions class="pa-0">
        <v-btn :disabled="code.length < 3" @click="getOrders(false, 'i=1,2'); orderDialog = true" color="#d7c194" depressed class="main-btn text-none mb-1 align-self-end" width="160" height="46"
               type="submit">
          {{ $t('view_your_orders') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="getOrders(true, 'i=1,3'); orderDialog = true" color="#d7c194" depressed class="main-btn text-none mb-1 align-self-end" width="160" height="46"
               type="submit">
          {{ $t('view_all_orders') }}
        </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        top
        right
        color="success"
        :timeout="3000"
    >
      {{ $t('order_reviewed') }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false;"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Legal",
  data() {
    return {
      orderDialog: false,
      products: [],
      snackbar: false,
      code: '',
      codeDialog: false,
      allReview: false,

      //ads
      counter: 0,
      current_ads: null,
      setAdTimeout: null,
      length_ads: 0,
    }
  },
  computed: {
    client() {
      return this.$store.getters.getClient
    },
    ads() {
      return this.$store.getters.getAds;
    },
    showAds() {
      return this.$store.getters.showAds;
    },
  },
  mounted(){
    this.$echo.channel('clientOrders.'+this.client?.code).listen('NewTableOrder', (payload) => {
      if(this.code!=''){
        this.getOrders(false, 'i=1,2')
      }else{
        this.getOrders(true, 'i=1,3')
      }
    });
  },
  methods: {
    router() {
      return router
    },
    resetApp(){
      this.$router.push(`/${localStorage.getItem('vina_hash')}/true`)
    },
    getOrders(review, status) {
      let code = null
      if(review) {
        this.allReview = review
        this.code = ''
      }
      else {
        this.allReview = review
        code = this.code
      }
      // /client/${this.client.id}
      vinaApi.get(`/table_orders`, {
        params: {
          "filter[status]": status,
          "filter[client_id]": this.client.id,
          "filter[code]": code,
          with: 'products'
        },
        headers: {
          "X-Localization": this.$store.getters.language,

        }
      }).then(response => {
        this.products = response.data
      })
    },
    changeStatus(item, yourOrders, changeStep, status) {
      item.status = item.status + changeStep

      vinaApi.put(`/table_orders/${item.id}`, item, {

        headers: {
          "X-Localization": this.$store.getters.language,

        }
      }).then(response => {
        // this.products = response.data
        if(response.status == 201 || response.status == 200) {
          this.snackbar = true
          this.getOrders(yourOrders, status)
        }
      })
    },
    legalBack() {
      let hash = localStorage.vina_hash;
      if (hash != 'undefined')
        this.$router.push('/' + hash + '')
    },
    setCurrent() {
      let time = 4000
      this.length_ads = this.ads?.length
      if (this.ads?.length > 0) {
        let ad = this.ads[this.counter];
        this.current_ads = ad;
        this.incrementCounter();
        if(!this.current_ads.video_url == false){
          return
        }
        else {
          time = 4000
        }
      }
      this.setAdTimeout = setTimeout(() => {
        this.setCurrent();
      }, time);
    },
    endVideo(){
      this.setCurrent()
    },
    incrementCounter() {
      if (this.counter === this.ads.length - 1) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    closeAd() {
      this.$store.dispatch("showAds", false);
      this.startTimeout();
    },
    startTimeout() {
      clearTimeout(this.menuTimeout);
      this.menuTimeout = setTimeout(() => {
        clearTimeout(this.setAdTimeout);
        this.setCurrent();
        this.$store.commit("clearFavs");
        this.$store.dispatch("showAds", true);
      }, 3 * 60 * 1000);
    },
  },
  created() {
    // this.getOrders()
    this.$store.dispatch('getClient', {fromServer: false});
    if (this.ads?.length > 0) {
      this.startTimeout();
    }
    document.addEventListener('click', this.startTimeout)
  },
  watch: {
    ads: function (newVal, oldVal) {
      if (oldVal?.length === 0 && newVal?.length > 0) {
        this.startTimeout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.legal {
  min-height: 100vh;
}

.legal-1 {
  height: 45vh;
  position: fixed;
  top: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, .3)), url("../assets/img/vinograd.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.legal-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.transparent-text {
  color: rgba(255, 255, 255, 0.5);
}

@media(max-width: 768px) {
  .legal-2 {
    margin-top: -150px !important;
  }
}

.card-outter {
  position: relative;
  padding-bottom: 150px;
}
.card-actions {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
</style>
